import { CSSObject } from '@emotion/react'

import type { BorderPreset, ColorName } from '../themeTypes'
import { color, radius, border } from '../tokens'

import { assignToken, Responsive } from './assignStyles'

export interface BorderProps {
  border?: Responsive<BorderPreset>
  borderColor?: Responsive<ColorName>
  borderTop?: Responsive<BorderPreset>
  borderBottom?: Responsive<BorderPreset>
  borderLeft?: Responsive<BorderPreset>
  borderRight?: Responsive<BorderPreset>
  /** Set `border-radius` using the spacing scale of the theme. */
  borderRadius?: Responsive<number | string>
  /** Set `border-top-left-radius` using the spacing scale of the theme. */
  borderTopLeftRadius?: Responsive<number | string>
  /** Set `border-top-right-radius` using the spacing scale of the theme. */
  borderTopRightRadius?: Responsive<number | string>
  /** Set `border-bottom-left-radius` using the spacing scale of the theme. */
  borderBottomLeftRadius?: Responsive<number | string>
  /** Set `border-bottom-right-radius` using the spacing scale of the theme. */
  borderBottomRightRadius?: Responsive<number | string>
}

export function borders(props: BorderProps) {
  const styles = {} as CSSObject
  assignToken(styles, color, 'borderColor', props.borderColor)

  assignToken(styles, border, 'border', props.border)
  assignToken(styles, border, 'borderTop', props.borderTop)
  assignToken(styles, border, 'borderBottom', props.borderBottom)
  assignToken(styles, border, 'borderLeft', props.borderLeft)
  assignToken(styles, border, 'borderRight', props.borderRight)

  assignToken(styles, radius, 'borderRadius', props.borderRadius)
  assignToken(styles, radius, 'borderTopLeftRadius', props.borderTopLeftRadius)
  assignToken(styles, radius, 'borderTopRightRadius', props.borderTopRightRadius)
  assignToken(styles, radius, 'borderBottomLeftRadius', props.borderBottomLeftRadius)
  assignToken(styles, radius, 'borderBottomRightRadius', props.borderBottomRightRadius)
  return styles
}
