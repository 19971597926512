import styled from '@dropscan/ds/styled'
import { MediaQueries } from '@dropscan/ds/ScreenSize'
import { space } from '@dropscan/ds/tokens'

export default styled('div')({
  [MediaQueries.Small]: {
    paddingLeft: space(4),
    paddingRight: space(4),
  },

  [MediaQueries.Medium]: {
    paddingLeft: space(8),
    paddingRight: space(8),
  },

  [MediaQueries.Large]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: `calc(1024px - 2 * ${space(8)})`,
    paddingLeft: 0,
    paddingRight: 0,
  },
})
