import { ColorName } from '../themeTypes'
import { color } from '../tokens'

import { assignToken, assignLiteral, Responsive } from './assignStyles'

export interface ColorProps {
  /** Shorthand for setting `color` based on a named theme color. */
  fg?: Responsive<ColorName>
  /** Shorthand for setting `background-color` based on a named theme color. */
  bg?: Responsive<ColorName>
  /** Set the CSS `background-color` directly, this takes precedence over the `bg` prop. */
  backgroundColor?: string
}
export function colors(props: ColorProps) {
  const styles = {}
  assignToken(styles, color, 'color', props.fg)
  assignToken(styles, color, 'backgroundColor', props.bg)
  assignLiteral(styles, 'backgroundColor', props.backgroundColor)
  return styles
}
