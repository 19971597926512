import { CSSObject } from '@emotion/react'

import { space } from '../tokens'
import { assignToken, Responsive } from './assignStyles'

export interface SpacingProps {
  /** Set `padding` using the spacing scale of the theme. */
  p?: Responsive<number>
  /** Set `padding-top` using the spacing scale of the theme. */
  pt?: Responsive<number>
  /** Set `padding-bottom` using the spacing scale of the theme. */
  pb?: Responsive<number>
  /** Set `padding-left` using the spacing scale of the theme. */
  pl?: Responsive<number>
  /** Set `padding-right` using the spacing scale of the theme. */
  pr?: Responsive<number>
  /** Set `padding-left` and `padding-right` using the spacing scale of the theme. */
  px?: Responsive<number>
  /** Set `padding-top` and `padding-bottom` using the spacing scale of the theme. */
  py?: Responsive<number>
  m?: Responsive<number | 'auto'>
  mt?: Responsive<number | 'auto'>
  mb?: Responsive<number | 'auto'>
  ml?: Responsive<number | 'auto'>
  mr?: Responsive<number | 'auto'>
  mx?: Responsive<number | 'auto'>
  my?: Responsive<number | 'auto'>
}

export function spacing({ p, pt, pb, pl, pr, px, py, m, mt, mb, ml, mr, mx, my }: SpacingProps) {
  const styles: CSSObject = {}
  assignToken(styles, space, 'padding', p)
  assignToken(styles, space, 'paddingTop', py)
  assignToken(styles, space, 'paddingBottom', py)
  assignToken(styles, space, 'paddingTop', pt)
  assignToken(styles, space, 'paddingBottom', pb)
  assignToken(styles, space, 'paddingLeft', px)
  assignToken(styles, space, 'paddingRight', px)
  assignToken(styles, space, 'paddingLeft', pl)
  assignToken(styles, space, 'paddingRight', pr)
  assignToken(styles, space, 'margin', m)
  assignToken(styles, space, 'marginTop', my)
  assignToken(styles, space, 'marginBottom', my)
  assignToken(styles, space, 'marginTop', mt)
  assignToken(styles, space, 'marginBottom', mb)
  assignToken(styles, space, 'marginLeft', mx)
  assignToken(styles, space, 'marginRight', mx)
  assignToken(styles, space, 'marginLeft', ml)
  assignToken(styles, space, 'marginRight', mr)
  return styles
}
