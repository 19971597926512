import { BorderPreset, ColorName, ShadowPreset, FontFamily } from './themeTypes'

export type TokenResolver<TokenType = number | string> = (value: TokenType) => string

// simplest case, just append the token to the prefix
const variant = <K extends string = string>(prefix: string) => (token: K) =>
  `var(--${prefix}-${token})`

let bodyStyle: { getPropertyValue: (arg0: string) => string }

// theme colors are defined as bare R,G,B triplets to allow adjusting opacity at usage sites.
const rgb = <K extends string = string>(prefix: string) => (token: K, alpha = 1) => {
  const property = `--${prefix}-${token}`
  if (alpha < 1) {
    if (typeof getComputedStyle !== 'undefined') {
      bodyStyle ??= getComputedStyle(document.body)
      const rgb = bodyStyle.getPropertyValue(property)
      return rgb.replace(/^rgb/, 'rgba').replace(/\)$/, `, ${alpha})`)
    } else {
      // browser support for this is not great yet
      return `rgba(from var(${property}) r g b ${alpha})`
    }
  } else {
    return `var(${property})`
  }
}

// for tokens representing measurements, we handle negative numbers and convert numbers between 0 and 1 to percentages
const measurement = (prefix: string): TokenResolver => {
  const resolve: TokenResolver = token => {
    if (typeof token === 'number') {
      if (token < 0) {
        return `calc(-1 * ${resolve(-1 * token)})`
      }
      if (token > 0 && token < 1) {
        return `${100 * token}%`
      } else {
        return `var(--${prefix}-${token})`
      }
    }
    // other strings are returned as-is (e.g. "100px")
    return token
  }

  return resolve
}

export const fontSize = measurement('font-size')
export const space = measurement('spacing')
export const radius = measurement('radius')

export const fontFamily = variant<FontFamily>('font-family')
export const color = rgb<ColorName>('color')
export const border = variant<BorderPreset>('border')
export const shadow = variant<ShadowPreset>('shadow')
export const font = variant<FontFamily>('font-family')
