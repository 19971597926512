import { Theme } from '../styled'
import { assignNamedStyle, Responsive } from './assignStyles'

export type TextPreset = keyof Theme['textPresets']

export interface TextPresetProps {
  textPreset?: Responsive<TextPreset>
}

export function textPreset(props: TextPresetProps & { theme: Theme }) {
  const styles = {}
  assignNamedStyle(styles, props.theme.textPresets, props.textPreset)
  return styles
}
