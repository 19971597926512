import { CSSObject } from '@emotion/react'

import type { FontFamily } from '../themeTypes'
import { fontSize, font as fontFamily } from '../tokens'

import { assignLiteral, assignToken, Responsive, ResponsiveCSS } from './assignStyles'

export interface FontProps {
  fontSize?: Responsive<number | string>
  fontFamily?: Responsive<FontFamily>
  fontWeight?: ResponsiveCSS<'fontWeight'>
  fontStyle?: ResponsiveCSS<'fontStyle'>
}

export function isFontProp(prop: string | number | symbol): boolean {
  return prop === 'fontSize' || prop === 'fontFamily' || prop === 'fontWeight'
}

export function font(props: FontProps) {
  const styles: CSSObject = {}
  assignToken(styles, fontSize, 'fontSize', props.fontSize)
  assignToken(styles, fontFamily, 'fontFamily', props.fontFamily)
  assignLiteral(styles, 'fontWeight', props.fontWeight)
  assignLiteral(styles, 'fontStyle', props.fontStyle)
  return styles
}
