import { CSSObject } from '@emotion/react'

import { assignToken, Responsive } from './assignStyles'
import { shadow } from '../tokens'
import { ShadowPreset } from '../themeTypes'

export interface BoxShadowProps {
  boxShadow?: Responsive<ShadowPreset>
}
export function boxShadows(props: BoxShadowProps) {
  const styles = {} as CSSObject
  assignToken(styles, shadow, 'boxShadow', props.boxShadow)
  return styles
}
