import Text from '@dropscan/ds/core/Text'
import { color, fontSize, space } from '@dropscan/ds/tokens'
import styled from '@dropscan/ds/styled'
import { MediaQueries } from '@dropscan/ds/ScreenSize'

import ContentSizer from './ContentSizer'

export default function Footer() {
  return (
    <ContentSizer css={{ marginTop: 'auto' }}>
      <Row>
        <Link href="https://www.dropscan.de">Zur Website</Link>
        <Link href="https://www.dropscan.de/kontakt">Kontakt</Link>
        <Link href="https://www.dropscan.de/agb">AGB &amp; Widerrufsbelehrung</Link>
        <Link href="https://www.dropscan.de/datenschutz/dse">Datenschutz</Link>
        <Link href="https://www.dropscan.de/impressum">Impressum</Link>
        <FooterText>© {new Date().getFullYear()} Dropscan GmbH, Berlin</FooterText>
      </Row>
    </ContentSizer>
  )
}

const Row = styled('div')({
  display: 'flex',
  paddingBottom: space(3),
  flexWrap: 'wrap',
  maxWidth: 1200,

  '& > :last-child': {
    marginLeft: 'auto',
  },

  [MediaQueries.Small]: {
    justifyContent: 'center',
    '& > :last-child': {
      marginLeft: 'auto',
    },
  },
})

const FooterText = styled(Text)({
  fontSize: fontSize(1),
  color: color('gray-300'),
  margin: space(3),

  [MediaQueries.Small]: {
    margin: space(2),
  },
})

const Link = FooterText.withComponent('a')
