import { CSSObject } from '@emotion/react'

import { Theme } from '../styled'

export function variants<Prop extends string, V extends Variants>(
  prop: Prop,
  vs: V,
  defaultVariant?: keyof V,
): VariantSelector<Prop, V> {
  return (props: VariantKeyProp<Prop, V> & { theme: Theme }): CSSObject | undefined => {
    const key = props[prop]
    if (typeof key === 'string' && vs.hasOwnProperty(key)) {
      return vs[key]
    } else if (typeof defaultVariant !== 'undefined') {
      return vs[defaultVariant]
    }
  }
}

export interface Variants {
  [key: string]: CSSObject
}

type VariantKeyProp<K extends string, Variants> = { [X in K]?: keyof Variants }

type VariantSelector<K extends string, V extends Variants> = (
  props: VariantKeyProp<K, V> & { theme: Theme },
) => CSSObject | undefined
