import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number
  square?: boolean
}

const SvgLogo = ({ square = false, size = 18, ...props }: Props) => (
  <svg
    viewBox={`0 0 ${square ? 137 : 671} 137`}
    width={square ? size : size * 4.8}
    height={size}
    {...props}
  >
    <title>Dropscan GmbH</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M136.802,17.997 C136.802,27.825 128.834,35.793 119.006,35.793 C109.177,35.793 101.209,27.825 101.209,17.997 C101.209,8.168 109.177,0.2 119.006,0.2 C128.834,0.2 136.802,8.168 136.802,17.997"
          fill="#40CC75"
        />
        <path
          d="M35.593,17.997 C35.593,27.825 27.625,35.793 17.797,35.793 C7.968,35.793 0,27.825 0,17.997 C0,8.168 7.968,0.2 17.797,0.2 C27.625,0.2 35.593,8.168 35.593,17.997"
          fill="#0F1317"
        />
        <mask id="mask-2" fill="white">
          <path d="M0,0.2 L670.57,0.2 L670.57,136.94 L0,136.94" />
        </mask>
        <g id="Clip-12" />
        <path
          d="M86.197,17.997 C86.197,27.825 78.23,35.793 68.401,35.793 C58.572,35.793 50.604,27.825 50.604,17.997 C50.604,8.168 58.572,0.2 68.401,0.2 C78.23,0.2 86.197,8.168 86.197,17.997"
          fill="#0F1317"
          mask="url(#mask-2)"
        />
        <path
          d="M136.802,119.204 C136.802,129.032 128.834,137 119.006,137 C109.177,137 101.209,129.032 101.209,119.204 C101.209,109.375 109.177,101.407 119.006,101.407 C128.834,101.407 136.802,109.375 136.802,119.204"
          fill="#715FFF"
          mask="url(#mask-2)"
        />
        <path
          d="M86.197,119.204 C86.197,129.032 78.23,137 68.401,137 C58.572,137 50.604,129.032 50.604,119.204 C50.604,109.375 58.572,101.407 68.401,101.407 C78.23,101.407 86.197,109.375 86.197,119.204"
          fill="#0F1317"
          mask="url(#ask-2)"
        />
        <path
          d="M35.593,119.204 C35.593,129.032 27.625,137 17.797,137 C7.968,137 0,129.032 0,119.204 C0,109.375 7.968,101.407 17.797,101.407 C27.625,101.407 35.593,109.375 35.593,119.204"
          fill="#0F1317"
          mask="url(#mask-2)"
        />
      </g>
      <path
        d="M35.593,68.6 C35.593,78.428 27.625,86.396 17.797,86.396 C7.968,86.396 0,78.428 0,68.6 C0,58.771 7.968,50.803 17.797,50.803 C27.625,50.803 35.593,58.771 35.593,68.6"
        fill="#0F1317"
      />
      <path
        d="M136.802,68.6 C136.802,78.428 128.834,86.396 119.006,86.396 C109.177,86.396 101.209,78.428 101.209,68.6 C101.209,58.771 109.177,50.803 119.006,50.803 C128.834,50.803 136.802,58.771 136.802,68.6"
        fill="#0F1317"
      />
    </g>
    <g>
      <path
        d="M262.04,68.6 C262.04,50.998 253.809,42.197 237.346,42.197 L226.936,42.197 L226.936,95.557 L235.485,95.557 C253.188,95.557 262.04,86.572 262.04,68.6 L262.04,68.6 Z M274.714,68.198 C274.714,80.336 271.344,89.615 264.605,96.036 C257.866,102.456 248.159,105.667 235.485,105.667 L214.916,105.667 L214.916,32.138 L237.648,32.138 C249.349,32.138 258.452,35.29 264.957,41.593 C271.461,47.897 274.714,56.765 274.714,68.198 L274.714,68.198 Z"
        fill="#0F1317"
      />
      <path
        d="M317.463,49.036 C319.843,49.036 321.804,49.204 323.347,49.539 L322.19,60.553 C320.513,60.151 318.77,59.95 316.959,59.95 C312.233,59.95 308.402,61.492 305.468,64.577 C302.534,67.662 301.067,71.669 301.067,76.596 L301.067,105.667 L289.249,105.667 L289.249,50.042 L298.502,50.042 L300.061,59.849 L300.665,59.849 C302.508,56.53 304.915,53.898 307.882,51.953 C310.849,50.009 314.043,49.036 317.463,49.036"
        fill="#0F1317"
      />
      <path
        d="M342.508,77.754 C342.508,90.595 347.252,97.016 356.741,97.016 C366.128,97.016 370.823,90.595 370.823,77.754 C370.823,65.047 366.096,58.693 356.641,58.693 C351.678,58.693 348.083,60.336 345.853,63.621 C343.623,66.907 342.508,71.618 342.508,77.754 L342.508,77.754 Z M382.995,77.754 C382.995,86.841 380.664,93.931 376.003,99.028 C371.342,104.124 364.855,106.673 356.54,106.673 C351.342,106.673 346.75,105.499 342.76,103.151 C338.769,100.805 335.702,97.435 333.556,93.043 C331.41,88.651 330.337,83.554 330.337,77.754 C330.337,68.735 332.651,61.694 337.278,56.631 C341.905,51.568 348.425,49.036 356.842,49.036 C364.889,49.036 371.258,51.626 375.954,56.807 C380.647,61.987 382.995,68.969 382.995,77.754 L382.995,77.754 Z"
        fill="#0F1317"
      />
      <path
        d="M421.92,58.693 C417.226,58.693 413.798,60.068 411.635,62.817 C409.473,65.566 408.391,69.958 408.391,75.993 L408.391,77.754 C408.391,84.526 409.464,89.43 411.61,92.464 C413.755,95.499 417.259,97.016 422.122,97.016 C426.211,97.016 429.364,95.34 431.577,91.987 C433.79,88.634 434.896,83.856 434.896,77.653 C434.896,71.417 433.797,66.698 431.602,63.495 C429.406,60.294 426.178,58.693 421.92,58.693 L421.92,58.693 Z M424.788,106.673 C417.747,106.673 412.281,104.141 408.391,99.078 L407.688,99.078 C408.157,103.772 408.391,106.622 408.391,107.628 L408.391,130.411 L396.573,130.411 L396.573,50.042 L406.128,50.042 C406.396,51.082 406.95,53.563 407.788,57.486 L408.391,57.486 C412.08,51.853 417.612,49.036 424.989,49.036 C431.928,49.036 437.335,51.551 441.208,56.58 C445.081,61.61 447.016,68.668 447.016,77.754 C447.016,86.841 445.046,93.931 441.107,99.028 C437.167,104.124 431.727,106.673 424.788,106.673 L424.788,106.673 Z"
        fill="#0F1317"
      />
      <path
        d="M498.114,89.824 C498.114,95.256 496.135,99.423 492.18,102.321 C488.223,105.221 482.556,106.673 475.18,106.673 C467.77,106.673 461.819,105.55 457.327,103.303 L457.327,93.093 C463.865,96.11 469.95,97.62 475.583,97.62 C482.858,97.62 486.497,95.424 486.497,91.031 C486.497,89.623 486.094,88.45 485.29,87.51 C484.485,86.572 483.16,85.599 481.317,84.594 C479.472,83.588 476.907,82.448 473.622,81.174 C467.217,78.692 462.884,76.212 460.621,73.73 C458.358,71.249 457.226,68.031 457.226,64.074 C457.226,59.313 459.145,55.617 462.985,52.985 C466.824,50.353 472.045,49.036 478.651,49.036 C485.189,49.036 491.375,50.361 497.209,53.01 L493.387,61.911 C487.384,59.43 482.338,58.19 478.249,58.19 C472.012,58.19 468.894,59.967 468.894,63.521 C468.894,65.264 469.707,66.74 471.333,67.947 C472.959,69.154 476.504,70.813 481.97,72.926 C486.563,74.703 489.899,76.329 491.979,77.803 C494.057,79.28 495.6,80.981 496.606,82.909 C497.612,84.837 498.114,87.141 498.114,89.824"
        fill="#0F1317"
      />
      <path
        d="M533.32,106.673 C524.904,106.673 518.508,104.217 514.133,99.303 C509.757,94.393 507.57,87.343 507.57,78.156 C507.57,68.802 509.858,61.61 514.435,56.58 C519.011,51.551 525.625,49.036 534.276,49.036 C540.142,49.036 545.423,50.126 550.118,52.305 L546.547,61.811 C541.55,59.867 537.426,58.894 534.174,58.894 C524.551,58.894 519.741,65.281 519.741,78.055 C519.741,84.292 520.939,88.977 523.336,92.112 C525.734,95.248 529.247,96.815 533.874,96.815 C539.136,96.815 544.116,95.507 548.81,92.891 L548.81,103.202 C546.698,104.442 544.443,105.331 542.045,105.868 C539.648,106.403 536.74,106.673 533.32,106.673"
        fill="#0F1317"
      />
      <path
        d="M579.086,97.62 C583.377,97.62 586.823,96.421 589.421,94.024 C592.02,91.627 593.32,88.264 593.32,83.94 L593.32,79.112 L586.177,79.413 C580.612,79.614 576.563,80.546 574.032,82.204 C571.5,83.864 570.235,86.404 570.235,89.824 C570.235,92.305 570.972,94.225 572.448,95.583 C573.922,96.94 576.135,97.62 579.086,97.62 L579.086,97.62 Z M596.689,105.667 L594.326,97.921 L593.923,97.921 C591.24,101.307 588.542,103.613 585.826,104.837 C583.11,106.059 579.623,106.673 575.365,106.673 C569.899,106.673 565.633,105.198 562.565,102.247 C559.498,99.296 557.963,95.122 557.963,89.723 C557.963,83.99 560.092,79.665 564.351,76.748 C568.608,73.831 575.096,72.239 583.814,71.969 L593.419,71.669 L593.419,68.701 C593.419,65.147 592.59,62.49 590.93,60.73 C589.271,58.969 586.697,58.089 583.21,58.089 C580.36,58.089 577.628,58.509 575.013,59.346 C572.398,60.185 569.882,61.174 567.468,62.314 L563.646,53.865 C566.664,52.289 569.966,51.091 573.554,50.269 C577.141,49.448 580.528,49.036 583.713,49.036 C590.788,49.036 596.127,50.579 599.732,53.663 C603.335,56.748 605.138,61.593 605.138,68.198 L605.138,105.667 L596.689,105.667 L596.689,105.667 Z"
        fill="#0F1317"
      />
      <path
        d="M670.57,105.667 L658.701,105.667 L658.701,71.468 C658.701,67.176 657.836,63.973 656.11,61.861 C654.383,59.749 651.642,58.693 647.887,58.693 C642.891,58.693 639.237,60.168 636.923,63.118 C634.61,66.069 633.453,71.014 633.453,77.955 L633.453,105.667 L621.634,105.667 L621.634,50.042 L630.888,50.042 L632.548,57.335 L633.152,57.335 C634.828,54.686 637.208,52.641 640.292,51.199 C643.377,49.758 646.797,49.036 650.552,49.036 C663.897,49.036 670.57,55.826 670.57,69.405 L670.57,105.667"
        fill="#0F1317"
      />
    </g>
  </svg>
)

export default SvgLogo
