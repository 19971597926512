import isPropValid from '@emotion/is-prop-valid'
import { Interpolation } from '@emotion/serialize'
import React from 'react'

import styled from '../styled'
import { BorderProps, borders } from '../styleFunctions/borders'
import { BoxShadowProps, boxShadows } from '../styleFunctions/boxShadows'
import { ColorProps, colors } from '../styleFunctions/colors'
import { flexItem, FlexItemProps } from '../styleFunctions/flex'
import { font, FontProps, isFontProp } from '../styleFunctions/font'
import { gridItem, GridItemProps } from '../styleFunctions/grid'
import { sizing, SizingProps } from '../styleFunctions/sizing'
import { spacing, SpacingProps } from '../styleFunctions/spacing'
import { textPreset, TextPresetProps } from '../styleFunctions/textPreset'
import { themeable, ThemeableProps } from '../styleFunctions/themeable'
import { Theme } from '../themeTypes'

export interface BoxProps
  extends ColorProps,
    FontProps,
    TextPresetProps,
    BorderProps,
    SpacingProps,
    SizingProps,
    FlexItemProps,
    GridItemProps,
    BoxShadowProps,
    ThemeableProps {
  children?: React.ReactNode
  css?: Interpolation<Theme>
}

/**
 * `Box` is a `div` with a bunch of shorthand props to set colors, borders, spacing etc. using values from our theme.
 *
 * @component
 */
export const Box = styled('div', {
  shouldForwardProp: prop => !isFontProp(prop) && isPropValid(prop),
})<BoxProps>(
  themeable,
  colors,
  textPreset,
  font,
  borders,
  spacing,
  sizing,
  flexItem,
  gridItem,
  boxShadows,
)
