import { Theme } from '../themeTypes'

export interface ThemeableProps {
  themedComponentName?: string
}

export function themeable(props: ThemeableProps & { theme: Theme }) {
  if (props.themedComponentName) {
    return themeStyles(props.themedComponentName)(props)
  }
}

export function themeStyles(themedComponentName: string) {
  return ({ theme }: { theme: Theme }) => {
    return theme.styles[themedComponentName]
  }
}
