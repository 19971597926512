import { CSSObject } from '@emotion/react'

import { assignToken, Responsive } from './assignStyles'
import { space } from '../tokens'

export interface SizingProps {
  /** Set `height` using the spacing scale of the theme. */
  h?: Responsive<number | string>
  /** Set `width` using the spacing scale of the theme. */
  w?: Responsive<number | string>
}

export function sizing(props: SizingProps) {
  const styles: CSSObject = {}
  assignToken(styles, space, 'height', props.h)
  assignToken(styles, space, 'width', props.w)
  return styles
}
